class {
  onCreate(input, out) {
    this.state = {
      errorModel: out.global.errorModel
    };
    console.log(this.state)
  }
}
<div>
  <AuthNAuthErrorPage errorModel=state.errorModel />
</div>
style {  }
